import React from 'react';
import Modal from '../Modal';
import { userHasRoleADMIN } from '../../Store/ParameterStore';
import Http from '../Http';
import { ModalLoadingContent } from '../../Components/Modal/ModalLoadingContent';

/**
 * Renvoie un booléen si la condition d'accès est validée pour **tous** les ids.
 * Si itemAccessCondition ou resource sont nuls, alors validateIds renvoie true pour être "transparent".
 *
 * @todo serait mieux dans une classe parente des BulkXxxx pour tester les ids avant chaque "run"
 *
 * @param {Array} ids
 * @param {(entity) => boolean|(entity) => Promise<boolean>} itemAccessCondition
 * @param {import("../APIResource/APIResource").APIResource} resource
 * @param {Array<string>} [entityFields] - Champs fournis dans l'entity à itemAccessCondition, pour accélérer la requête back.
 * @param {import('../APIResource/APIResource').APIResourceBulkParams["bulkValidateRoles"]} [bulkValidateRoles]
 * @param {string} [idField] - Nom du champ identifiant de l'entity, par défaut = "id"
 * @returns {Promise<boolean>}
 */
export const validateIds = async (ids, itemAccessCondition, resource, entityFields = undefined, bulkValidateRoles = undefined, idField = 'id') => {
    if (
        userHasRoleADMIN() // pour des questions de perf pour les admins, on considère qu'ils savent ce qu'ils font
        || (!itemAccessCondition && !bulkValidateRoles)
        || !resource
        || !ids.length
    ) return true;

    /**
     * S'il y a des bulkValidateRoles, alors on fait une requete au back pour savoir si la modification de tous les ids
     * est possible, pour les rôles donnés.
     */
    if (bulkValidateRoles && bulkValidateRoles.length) {
        const response = await Http.post(`${resource.resourceId}/bulk/validateIds`, {
            ids: ids,
            attributes: bulkValidateRoles,
        });
        return response['hydra:member']['allow_bulk_action'];
    }

    /**
     * On clone APIResource si on filtre sur certains champs, sinon on risque de supprimer de `APIResource.items`
     * les données dont on dispose déjà.
     */
    const bulkResource = resource.clone('bulk_' + resource.instanceId);
    // On précharge les entités dans la resource, puis on filtre celles qui nous intéressent :
    const bulkFilters =
        ids.length > 50 // Nombre arbitraire : au delà on charge toutes les entités, et en deçà on ne charge que les ids demandés
            ? bulkResource.currentList.filters
            : {
                  ...bulkResource.currentList.filters,
                  ...ids.reduce((carry, id, i) => ((carry[`${idField}[${i}]`] = id), carry), {}),
              };
    await bulkResource.apiGetCollection({ page: 1, rowsPerPage: 1000000, filters: bulkFilters, fields: entityFields });
    const entities = await Promise.all(ids.map((id) => bulkResource.getItem(id)));
    const conditions = await Promise.all(entities.map((entity) => itemAccessCondition(entity)));
    return conditions.every((c) => c);
};

/**
 * Ouvre une Modal vide qui charge.
 * @returns {void}
 */
export const openLoadingModal = () => {
    Modal.open({
        title: 'Loading',
        content: <ModalLoadingContent />,
        size: 'medium',
    });
};
